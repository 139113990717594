/************************************************

Stylesheet: Main Stylesheet

*************************************************/

body:before {content: ""; display: block; position: absolute; z-index: -1; left: 0; top: 0; width: 100%; height: rem-calc(140); background-color: $primary-color;}


// TYPOGRAPHY
// -------------------------------------------------------------------------------------------------------------------

@mixin header() {text-transform: uppercase; font-family: $header-font-family; letter-spacing: 0.1em;}

h1, h2, h3, h4, h5, h6 {@include header(); margin-top: 1em; 
    @include breakpoint(medium) {
        &:first-child {margin-top: 0;}
        &:last-child {margin-bottom: 0;}
    }
}

h1, h2 {font-weight: $global-weight-light; margin-bottom: .5em;}

h3, h4 {font-family: $body-font-family; text-transform: none; letter-spacing: 0.025em; font-weight: $global-weight-bold; margin-bottom: .6666em;}

h5, h6 {
    + h1, + h2, + h3 {margin-top: .6666em;}
}


li {margin-bottom: $list-margin-bottom;}


.button {
    &:hover {color: $white;}
    &:after {display: none !important;}
}


// Highlight Text
// ------

.highlight {color: $secondary-color;}



// Lead Text
// ------

.lead {font-size: $lead-font-size;}



// CTA Link
// ------

.cta-link {font-size: $lead-font-size; color: $secondary-color; font-weight: $global-weight-bold; transition: color .15s ease-in-out;
    &:after {@include icon_font($char: $icon-arrow-right); line-height: 0; font-size: rem-calc(24); vertical-align: middle; margin-left: .6666rem;}
    &:hover {color: $primary-color;}
}



// Blockquote
// ------

blockquote {
    p {margin-bottom: 0;}
    cite {}
}


// Horizontal List
// ------

.horizontal-list {@include horizontal_list();}







// IMAGES, VIDEOS & GALLERIES
// -------------------------------------------------------------------------------------------------------------------


.img {position: relative; display: block; background: transparent no-repeat center/cover;
    &.fixed {background-attachment: fixed !important;}
    
    .hover {visibility: hidden; position: absolute; left: 0; top: 0; right: 0; bottom: 0;}
    &:hover .hover {visibility: visible;}
}

.icon {background: transparent no-repeat center/contain; height: rem-calc(140); margin-bottom: 1.25rem;}




// FORM STYLES
// -------------------------------------------------------------------------------------------------------------------

[type=color], [type=date], [type=datetime-local], [type=datetime], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week] {border-top: 0; border-left: 0; border-right: 0;
    &:focus {border-top: 0; border-left: 0; border-right: 0;}
}

.gform_validation_container {display: none !important;}
.validation_error {display: none;}

.validation_message {color: $alert-color; font-size: $small-font-size; margin: rem-calc(-10 0 10);}
.gform_confirmation_message {}
.gform_ajax_spinner {}

.gform_body {margin-bottom: 2rem;}
.gform_footer {
    input[type=submit] {font-size: 1.25rem;}
}
.gfield {margin-bottom: 0;}

.gfield_label {@include header(); font-size: rem-calc(16); margin-bottom: 1rem;}

.hide_label {
    .gfield_label {display: none;}
}

*:not(textarea)::placeholder {@include header(); font-size: rem-calc(12); line-height: rem-calc(23);}
textarea:focus::placeholder {color: $secondary-color;}

input[type=checkbox],
input[type="radio"] {display: none;

    + label {position: relative; margin: 0; clear: none; cursor: pointer; line-height: rem-calc(24); @include header(); font-size: rem-calc(12);
        &:before {content: ""; display: inline-block; width: rem-calc(24); height: rem-calc(24); border: #{rem-calc(3) solid $primary-color}; margin-right: 1em; background-color: $white; vertical-align: top;}
    }
    &:checked + label:before {background-color: $secondary-color; border-color: $secondary-color;}
}

input[type="radio"] {
    + label:before {border-radius: 100%;}
}

.horizontal {margin: rem-calc(30 0);
    ul {display: flex; flex-flow: row wrap;
        li {display: block; flex: 0 0 auto; margin-right: 2rem;}
    }
}

@include breakpoint(medium) {

    .gform_fields {display: flex; flex-flow: row wrap; justify-content: space-between;
        > li {flex: 0 0 100%; width: 100%;
            &.gf_half {flex: 0 0 calc(50% - #{rem-calc(10)}); width: calc(50% - #{rem-calc(10)});}
        }
    }

    .ginput_container_name {display: flex; flex-flow: row nowrap; justify-content: space-between;
        > span {flex: 0 0 calc(50% - #{rem-calc(10)}); width: calc(50% - #{rem-calc(10)});}
    }

    .gfield_checkbox {display: flex; flex-flow: row wrap; justify-content: space-between;
        li {flex: 0 0 calc(50% - #{rem-calc(10)}); width: calc(50% - #{rem-calc(10)});}
    }
}





// FRAMEWORK
// -------------------------------------------------------------------------------------------------------------------


// Sticky Footer
// ------

html {height: 100%;}
.site {display: flex; flex-direction: column; height: 100%;}
.site-header,
.site-footer {flex: none;}
.site-content {flex: 1 0 auto; width: 100%; }


// Header
// ------

.site-header {height: rem-calc(60); position: fixed; z-index: 10; width: 100%; padding: rem-calc(15 0); color: $white; background-color: $primary-color; transition: height .4s ease-in-out, box-shadow .4s ease-in-out;
    &.minimize {box-shadow: #{rem-calc(0 2 3)} rgba(0,0,0,.1);}    

    @include breakpoint(medium) {height: rem-calc(120); padding: rem-calc(18 0);
        &.minimize {height: rem-calc(60);}
    }
    
    .cell {display: flex; flex-flow: row nowrap; justify-content: space-between;}
}

// Logo
// ------

$logo_w : rem-calc(235);
$logo_h : rem-calc(24);

.logo {position: relative; width: $logo_w; height: $logo_h; margin: rem-calc(2 0 0) !important; line-height: 0; font-size: 0; 
    a {display: block; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: transparent url(../images/logo.svg) no-repeat center/contain;}

    @include breakpoint(medium) {margin: rem-calc(30 0 0) !important; transition: margin .4s ease-in-out;
        .minimize & {margin: 0 !important;}
    }
    @include breakpoint(medium only) {position: absolute; top: rem-calc(18); margin-top: 0 !important; transition: top .4s ease-in-out, opacity .4s ease-in-out;
        .minimize & {top: 0; opacity: 0;}
    }
}

// Hamburger Icon
// ------
$hamburger-padding-x                       : 0;
$hamburger-padding-y                       : 0;
$hamburger-layer-width                     : rem-calc(20);
$hamburger-layer-height                    : rem-calc(2);
$hamburger-layer-spacing                   : rem-calc(5);
$hamburger-layer-color                     : $white;
$hamburger-layer-border-radius             : 0;
$hamburger-hover-opacity                   : 0.7;
$hamburger-hover-transition-duration       : 0.15s;
$hamburger-hover-transition-timing-function: linear;

@import "hamburgers/hamburgers";

.site-header .hamburger {margin-top: rem-calc(6);
    @include breakpoint(medium) {display: none;}
}

// Top Navigation
// ------

.topNav {

    a {color: $white; font-size: $small-font-size;
        &:hover {color: $secondary-color;}
    }

    ul {list-style: none; margin: 0; 
        li {margin-bottom: 0;}
    }

    #menu-primary {
        a {@include header();}
    }

    @include breakpoint(medium) {flex: 1 1 auto; text-align: right; display: flex; flex-flow: column nowrap; justify-content: flex-end;
        > ul {@include horizontal_list($margin: .75em, $align: right); flex: 0 0 auto;
            > li {
                &.current-menu-item > a,
                &.current-menu-ancestor > a,
                &.current_page_parent > a {color: $secondary-color;}
            }
        }

        > ul#menu-auxiliary {@include horizontal_list($margin: 6px, $align: right);  margin-bottom: rem-calc(20); transition: margin .4s ease-in-out, opacity .4s ease-in-out;
            > li:after {content: ""; display: block; height: rem-calc(3); margin: rem-calc(3 0 -3); background-color: $secondary-color;}
            // li:not(:last-child) a:after {content: "/"; display: inline; margin: rem-calc(0 5);}
            .minimize & {margin: rem-calc(-30 0 0); opacity: 0;}
        }
    }

    @include breakpoint(medium only) {
        > ul#menu-auxiliary {margin: rem-calc(-5 0 35);
            .minimize & {margin: rem-calc(-25 0 0);}
        }

        > ul#menu-primary {@include horizontal_list($margin: .75em, $align: center);}
    }

    @include breakpoint(small down) {display: none; position: absolute; left: 0; top: rem-calc(60); width: 100%; background-color: $primary-color; transition: top .4s ease-in-out, box-shadow .4s ease-in-out;
        .open & {box-shadow: #{rem-calc(0 2 3)} rgba(0,0,0,.1);}

        a {display: block;}

        > ul#menu-auxiliary {position: absolute; top: rem-calc(-2); right: 0; 
            &:before {content: ""; position: absolute; top: 0; left: 0; display: block; height: 100%; width: rem-calc(3); background-color: $secondary-color;}
            a {margin: rem-calc(7 20);}
            li:not(:first-child) a {margin-top: rem-calc(10);}
        }

        > ul#menu-primary {background-color: $white; color: $medium-gray; 
            a {margin: 0; padding: rem-calc(7 20); color: $primary-color;}
            .current-menu-item a,
            .current-menu-ancestor a,
            .current_page_parent a {color: $secondary-color;}
        
            .menu-item-has-children:nth-child(1) {background-color: $primary-color; color: $white;
                .sub-menu {padding-top: 0;}
                a {color: $white;}
                .current-menu-item a,
                .current-menu-ancestor a,
                .current_page_parent a {color: $secondary-color;}
            }
            .menu-item-has-children:nth-child(2) {background-color: $secondary-color; color: $white;
                a {color: $white;}
                .current-menu-item a,
                .current-menu-ancestor a,
                .current_page_parent a {color: $primary-color;}

            }

            > li.menu-item-has-children + li:not(.menu-item-has-children) {padding-top: rem-calc(15);}
            > li:last-child:not(.menu-item-has-children) {padding-bottom: rem-calc(15);}
        }
    }


    .menu-item-has-children {
        
        @include breakpoint(medium) {position: relative;
            > a {padding-right: 1em;}
            .minimize &:after {color: $secondary-color;}

            &:hover .sub-menu {pointer-events: auto; transform: translate(0,0); opacity: 1;}
        }
        @include breakpoint(small down) {
            > a {display: none;}
        }
    }


    .sub-menu {padding: rem-calc(15 0);

        @include breakpoint(medium) {text-align: left; pointer-events: none; opacity: 0; transform: translate(-1em,0); position: absolute; left: 0; width: rem-calc(184); padding: rem-calc(18 0); transition: background-color .4s ease-in-out, box-shadow .4s ease-in-out, transform .3s ease-in-out, opacity .3s ease-in-out; 
            a {display: block; position: relative; padding: rem-calc(7 20 7 23); background-color: $secondary-color; border-left: 3px solid $primary-color;
                // &:before {content: ""; display: block; position: absolute; left: 0; top: 0; width: rem-calc(3); height: 100%; background-color: $secondary-color;}
                &:hover {color: $primary-color;}
            }
            .current-menu-item a,
            .current-menu-ancestor a,
            .current_page_parent a {color: $primary-color;}

            li:first-child a {padding-top: rem-calc(20);}
            li:last-child a {padding-bottom: rem-calc(20);}
            
        }
    }
    
}


// Body
// ------

.site-content {padding-top: rem-calc(60); 
    @include breakpoint(medium) {padding-top: rem-calc(120);}
}




// Footer
// ------

.newsletter-cta {display: flex; flex-flow: row wrap; justify-content: flex-start;
    h5 {flex: 1 1 auto; margin: rem-calc(15 30 10 0);}
    .gform_wrapper {flex: 1 1 auto; margin: rem-calc(10 0);
        form {display: flex; flex-flow: row nowrap; justify-content: stretch; max-width: rem-calc(380);}
        .gform_body {flex: 1 1 auto;}
        .gform_footer {flex: 0 0 auto;}
        input {margin-bottom: 0; height: rem-calc(36);
            &[type=email] {background-color: transparent; color: $primary-color; border: 0; border-radius: 0; border-bottom: #{rem-calc(3)} solid $primary-color; box-shadow: none;
                &::placeholder {color: $secondary-color;}
            }
            &[type=submit] {@include header(); font-size: rem-calc(12); color: $primary-color; background-color: transparent; border-color: transparent; border-radius: 0; border-right: 0; border-left: 0; border-top: 0; border-bottom: #{rem-calc(3)} solid $secondary-color; transition: color .15s ease-in-out, border-color .15s ease-in-out;
                &:hover {color: $secondary-color;}
            }
        }
    }
    // .gform_anchor {flex: 0 0 0; width: 0;}
    .gform_confirmation_wrapper {flex: 1 1 #{rem-calc(380)}; margin: rem-calc(10 0); }
}

.site-footer {color: $white; background-color: $primary-color; font-size: $small-font-size; padding-bottom: rem-calc(15) !important; 
    ul {list-style: none; margin: 0;}
    a {color: $white;
        &:hover {color: $secondary-color;}
    }
    h6 {margin-top: rem-calc(30);}

    .footer-cta {

        .newsletter-cta {
            .gform_wrapper {
                input {
                    &[type=email] {color: $white; border-bottom-color: $white;
                        &::placeholder {color: $secondary-color;}
                    }
                    &[type=submit] {color: $white; border-bottom-color: $secondary-color;
                        &:hover {color: $secondary-color;}
                    }
                }
            }
        }

        hr {margin-top: 0;
            @include breakpoint(small only) {display: none;}
        }
    }

    .footer-columns {margin-bottom: rem-calc(30);}

    .footer-bottom {
        .footer-bottom-source-org {margin-bottom: rem-calc(30); color: $secondary-color;
            a {color: $secondary-color;
                &:hover {color: $white;}
            }
            p, nav, ul, li {display: inline;}
            p, li {margin-right: 1em;}
        }
        .footer-social {margin-bottom: rem-calc(30);}
    }
}


.socialNav ul {@include horizontal_list($margin: .5em); margin-top: rem-calc(-10);
    a {font-size: 0; color: $primary-color; display: inline-block; vertical-align: middle; transition: background-color .15s ease-in-out;
        &:before {font-size: rem-calc(19); text-align: center; line-height: rem-calc(32) !important; display: inline-block; width: rem-calc(32); height: rem-calc(32); border-radius: 50%; background-color: $white; transition: background-color .15s ease-in-out;}
        &:hover {color: $primary-color;
            &:before {background-color: $secondary-color;}
        }
    }
    .linkedin a:before {@include icon_font($char: $icon-linkedin);}
    .twitter a:before {@include icon_font($char: $icon-twitter);}
    .facebook a:before {@include icon_font($char: $icon-facebook);}
    .instagram a:before {@include icon_font($char: $icon-instagram);}
}









// COMPONENT STYLES
// -------------------------------------------------------------------------------------------------------------------



// Map
// ------

/* fixes potential theme css conflict */
.acf-map img {max-width: inherit !important;}



// Transition Elements
// ------

body:not(.archive) {
    .showOnView {opacity: 0; box-shadow: 0 0 1px rgba(0, 0, 0, 0);} // box shadow hack to remove redraw pixels
}



// Page Header
// ------

.page-header {background-color: $lighter-gray;
    .entry-content {padding-left: rem-calc(20); padding-right: rem-calc(20);}

    @include breakpoint(medium) {display: flex; flex-flow: row nowrap; background-color: $white; min-height: 30vw;
        .entry-content {padding-right: rem-calc(30); padding-left: rem-calc(30); padding-bottom: rem-calc(120);
            > *:last-child {margin-bottom: 0;}
        }

        .cell {
            &.medium-shrink {flex: 1 1 auto;
                &:first-child {background-color: $lighter-gray; position: relative;
                    &:before {position: absolute; content: ""; display: block; left: 0; top: rem-calc(92); width: 100%; height: rem-calc(3); background-color: $secondary-color;}
                }
            }
        }

        &__main.cell.medium-auto {flex: 1 1 66.6666%; max-width: calc(#{$global-width} * .666666); background-color: $lighter-gray;
            .entry-content {padding-right: 20%;}
        }
        &__side.cell.medium-auto {flex: 1 1 33.3333%; max-width: calc(#{$global-width} * .333333); text-align: right;
            .entry-content {}
        }
    }

    @include breakpoint(large) {
        .cell.medium-shrink:first-child:before {top: rem-calc(122);}
    }

    @include breakpoint(small only) {
        &__main .panel {padding-bottom: 0;}
        &__side .panel {padding-top: 0;}
    }
}

.page-sub-header {
    .subtitle {text-align: center; max-width: rem-calc(290); margin-right: auto; margin-left: auto;}
    .page-header-image {width: 100%; padding: 0 0 62%;}

    @include breakpoint(medium) {display: flex; flex-flow: row nowrap; margin-bottom: rem-calc(60);
        .subtitle {width: 33.3334%; align-self: stretch; display: flex; flex-flow: column nowrap; justify-content: center;
            .connection {flex: 1 1 auto; width: rem-calc(3); margin: 0 auto; background-color: $primary-color; position: relative; z-index: 2;
                &:before {content: ""; display: block; position: absolute; left: 0; width: rem-calc(3); top: rem-calc(-60); height: rem-calc(60); background-color: $secondary-color;}
                &:last-child:after {content: ""; display: block; position: absolute; left: 0; width: rem-calc(3); bottom: rem-calc(-120); height: rem-calc(120); background-color: $primary-color;}
                + h3 {flex: 0 0 auto;}
            }
        }
        .page-header-image {width: 66.6666%; padding-bottom: 42%; margin-top: rem-calc(-60);}
    }
}



// Panel
// -------

.panel {position: relative; padding-top: rem-calc(30); padding-bottom: rem-calc(30); 
    @include breakpoint(medium) {padding-top: rem-calc(60); padding-bottom: rem-calc(60);}
    @include breakpoint(large) {padding-top: rem-calc(90); padding-bottom: rem-calc(90);}
}

.connector {position: relative;
    &:not(:first-child) {
        &:before {content: ""; display: block; position: absolute; left: calc(50% - #{rem-calc(1.5)}); top: 0; width: rem-calc(3); height: rem-calc(30); background-color: $primary-color;
            @include breakpoint(medium) {height: rem-calc(60);}
        }
    }
    &:not(:last-child) {
        &:after {content: ""; display: block; position: absolute; left: calc(50% - #{rem-calc(1.5)}); bottom: 0; width: rem-calc(3); height: rem-calc(30); background-color: $primary-color;
            @include breakpoint(medium) {height: rem-calc(60);}
        }
    }
}



// Loading Animation
// ------

#postslist {margin-bottom: rem-calc(30);}

#paginateNav {text-align: center;
    .load-more {font-size: 1.25rem; display: flex; flex-flow: row nowrap; justify-content: center; width: rem-calc(220); margin: 0 auto #{rem-calc(30)}; background-color: $secondary-color; color: $white;}
    .label {flex: 1 0 auto; padding: rem-calc(0 15);}
    .spinner {flex: 0 0 auto; opacity: 0; margin: rem-calc(3 0); text-align: center; overflow: hidden; display: flex; flex-flow: row nowrap; justify-content: space-around; height: rem-calc(12); width: 0; transition: opacity .15s ease-in-out, width .15s ease-in-out;
        > div {flex: 0 0 25%; width: 25%; height: 100%; background-color: $white; border-radius: 100%; animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            &.bounce1 {animation-delay: -0.32s;}
            &.bounce2 {animation-delay: -0.16s;}
        }
    }
    &.loading {
        .spinner {opacity: 1; width: rem-calc(48);}
    }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
}   










// VIEWS
// -------------------------------------------------------------------------------------------------------------------


// Front Page (Home)
// ------

body.home {
    .site-header:not(.minimize):not(.open) {background-color: transparent; transition: height .4s ease-in-out, box-shadow .4s ease-in-out, background .4s ease-in-out;}
    .site-content {padding-top: 0;}
}

.home-panels {color: $white; background-color: $primary-color; padding-bottom: calc(#{rem-calc(30)} + 10vw);
    a {color: inherit;
        &:hover {color: $secondary-color;}
    }

    &__intro {min-height: calc(100vh - #{rem-calc(60)}); align-items: center; padding-top: 20vh;

        aside {padding-top: 5vh; padding-bottom: 10vh; max-width: rem-calc(150); font-weight: $global-weight-bold;
            a {}
            &:after {content: ""; display: block; height: rem-calc(3); background-color: $secondary-color; margin: rem-calc(10 0 20);}
        }

        @include breakpoint(medium) {align-items: flex-end; height: 90vh; max-height: 50vw; min-height: rem-calc(500); padding-top: rem-calc(120); padding-bottom: rem-calc(30);
            .entry-content {max-width: rem-calc(480);}
            aside {margin-right: 0; margin-left: auto; text-align: right; padding-top: 0; padding-bottom: 0;}
        }
        @include breakpoint(large) {
            .entry-content {max-width: rem-calc(600);}
            h1 {font-size: rem-calc(84);}
        }
        @include breakpoint(xlarge) {
            .entry-content {max-width: rem-calc(660);}
            h1 {font-size: rem-calc(92);}
        }
    }

    &__panel {text-align: center; margin-bottom: rem-calc(30);
        &:before {content: ""; display: block; height: 25vh; width: rem-calc(3); background-color: $white; margin: 0 auto #{rem-calc(30)};}

        h1, h2, h3, h4 {max-width: rem-calc(420); margin-right: auto; margin-left: auto;}

        @include breakpoint(small only) {
            &:first-child:before {display: none;}
        }
    }
}


// Quote CTA
// ------


.quote-cta {background-color: $lighter-gray;
    .entry-content {padding-left: rem-calc(20); padding-right: rem-calc(20);
        

        p {font-weight: $global-weight-bold;}
    }

    @include breakpoint(medium) {display: flex; flex-flow: row nowrap; background-color: $white;
        .entry-content {padding-right: rem-calc(30); padding-left: rem-calc(30);
            > *:last-child {margin-bottom: 0;}

        }

        .cell {
            &.medium-shrink {flex: 1 1 auto;
                &:first-child {background-color: $lighter-gray;}
            }
        }

        header.cell.medium-auto {flex: 1 1 66.6666%; max-width: calc(#{$global-width} * .666666); background-color: $lighter-gray;
            .entry-content {display: flex; flex-flow: row nowrap; justify-content: stretch; align-items: flex-start; padding-right: 0;
                h2 {flex: 0 0 auto;}
                .connection {flex: 1 1 auto; height: rem-calc(3); margin-top: rem-calc(35); background-color: $primary-color;}
            }
        }
        section.cell.medium-auto {flex: 1 1 33.3333%; max-width: calc(#{$global-width} * .333333); text-align: right;
            .entry-content {display: flex; flex-flow: row nowrap; justify-content: stretch; align-items: flex-start; padding-left: 0;
                &__inner {flex: 0 0 auto; margin-left: rem-calc(20);}
                .connection {flex: 1 1 auto; height: rem-calc(3); margin-top: rem-calc(35); background-color: $secondary-color;}
                p {margin: rem-calc(25 0);}
            }
        }
    }

    @include breakpoint(small only) {
        header .panel {padding-bottom: 0;}
        section .panel {padding-top: 0;}
    }
}



// Our Values
// ------

.our-values {text-align: center;
    

    &__intro {color: $white;
        &.connector:before, &.connector:after {background-color: $white;}

        h1 {margin-bottom: rem-calc(25);}
        .value {max-width: rem-calc(300); margin: #{rem-calc(24)} auto;}

        @include breakpoint(medium) {
            h1 {margin-bottom: rem-calc(55);}
        }
    }

    &__panel {
        &.dark {color: $white;
            &.connector:before, &.connector:after {background-color: $white;}
        }
    }

}



// Team
// ------

.team {
    &__intro {}
    &__bios {
        @include breakpoint(medium) {padding-top: 0;}
    }
}

.bio {display: flex; flex-flow: column nowrap; justify-content: stretch; margin-bottom: rem-calc(20);
    .img {flex: 0 0 0; padding-bottom: 80%; background-color: $light-gray;}
    .summary {flex: 1 1 auto; background-color: $lighter-gray; padding: rem-calc(30 30 20);}
    .meta-title {color: $secondary-color; font-weight: $global-weight-bold;}
    @include breakpoint(medium) {margin-bottom: rem-calc(30);}
}



// Careers
// ------

.careers {
    &__apply {background-color: $primary-color; color: $white; text-align: center;
        h3 {max-width: rem-calc(600); margin-left: auto; margin-right: auto;}

        @include breakpoint(medium) {padding-top: rem-calc(120); padding-bottom: rem-calc(120);}
    }

    &__list {
        h3 {text-align: center;}
    }
}

#interactive-application {display: block; margin-left: auto; margin-right: auto;
    @include breakpoint(small down) {width: rem-calc(290); height: rem-calc(120); }
}

.careers-listing {margin-bottom: rem-calc(30);
    .resumator-jobs-text {font-family: $body-font-family !important;}
    .resumator-job {}
    .resumator-job-title {color: $primary-color; padding-top: rem-calc(20);}
    .resumator-job-info, .resumator-job-info-details {color: darken($dark-gray, 15%); font-weight: $global-weight-bold;}
    .resumator-job-heading {color: $medium-gray;}
    .resumator-job-view-details {margin-top: 2rem;}
    .resumator-job-link {font-size: 1em; color: $secondary-color; font-weight: $global-weight-bold;
        &:hover {color: $primary-color;}
    }
    .resumator-hide-details {color: $medium-gray;}
    .resumator-buttons {
        a[type=button],
        input[type=button] {font-family: inherit; font-size: 100%; line-height: $global-lineheight; margin: 0; padding: rem-calc(7 15); border: 0; background-color: $secondary-color; color: $primary-color; overflow: visible; text-transform: none;}
        input[type=button] {-webkit-appearance: button; color: $white; font-weight: $global-weight-bold;}
        a[type=button] {display: block; background-color: $medium-gray;}
    }
}




// Story
// ------

.story {

    &__intro {
        h1 {max-width: rem-calc(640);}

        aside {display: none;
            @include breakpoint(large) {display: flex; flex-flow: column nowrap; justify-content: stretch; text-align: right;
                .entry-content {flex: 0 0 auto; padding-bottom: rem-calc(15);}
                .connection {flex: 1 1 auto; width: rem-calc(3); background-color: $secondary-color; margin-left: auto; margin-right: rem-calc(2);}
            }
        }
    }


    &__banner,
    .milestone-fullscreen {
        .grid-container {
            @include breakpoint(medium) {min-height: 33.3333vw;}
        }

        h5, p {padding: rem-calc(15); position: relative; display: inline-block;
            &:before {content: ""; display: block; position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%; background: transparent no-repeat center/contain; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 410.94 129.92' preserveAspectRatio='none'%3E%3Cpolygon points='403.9 126.92 10.62 129.92 0 0 410.94 4 403.9 126.92' style='fill:%23fff'/%3E%3C/svg%3E");}
            span {position: relative; z-index: 2;}
        }
        h5:before {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 403.94 88.92' preserveAspectRatio='none'%3E%3Cpolygon points='5.04 0 398.32 7 403.94 86.92 0 88.92 5.04 0' style='fill:%23fff'/%3E%3C/svg%3E");}
    }


    &__banner {
        .grid-container {
            @include breakpoint(large) {position: relative;
                &:before {content: ""; display: block; position: absolute; top: rem-calc(-90); right: rem-calc(32); width: rem-calc(3); height: rem-calc(60); background-color: $white;}
            }
        }
    }


    &__milestones {position: relative; padding-bottom: rem-calc(20); 
        @include breakpoint(medium) {padding-bottom: rem-calc(60);}
        @include breakpoint(large) {padding-bottom: rem-calc(90);}
    }

    .milestone {position: relative; z-index: 2;

        .img {position: relative; padding: 50% 0;
            h2 {position: absolute; color: $white; padding: rem-calc(5 15);
                &:before {content: ""; display: block; position: absolute; z-index: 1; left: 0; top: 0; width: 100%; height: 100%; background: transparent no-repeat center/contain; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 410.94 129.92' preserveAspectRatio='none'%3E%3Cpolygon points='403.9 126.92 10.62 129.92 0 0 410.94 4 403.9 126.92' style='fill:%2366c7d1'/%3E%3C/svg%3E");}
                span {position: relative; z-index: 2;}

                &.topleft {top: rem-calc(30); left: rem-calc(-30);}
                &.topright {top: rem-calc(30); right: rem-calc(-30);}
                &.middleleft {top: calc(50% - #{rem-calc(30)}); left: rem-calc(-30);}
                &.middleright {top: calc(50% - #{rem-calc(30)}); right: rem-calc(-30);}
                &.bottomleft {bottom: rem-calc(30); left: rem-calc(-30);}
                &.bottomright {bottom: rem-calc(30); right: rem-calc(-30);}

                @include breakpoint(medium only) {font-size: rem-calc(40);}
                @include breakpoint(small only) {font-size: rem-calc(30);}
            }
        }

        @include breakpoint(medium) {
            .cell {margin-top: rem-calc(-60);}
            &:not(:last-child) .cell {margin-bottom: rem-calc(-60);}

            &:nth-child(odd) {justify-content: flex-end;
                .cell {padding-right: rem-calc(30);}
                h5 {text-align: right;}
            }
            &:nth-child(even) {
                .cell {padding-left: rem-calc(30);}
            }
        }

        @include breakpoint(small only) {
            .cell {padding: rem-calc(30 30 0 30);}
        }
    }

    .milestone + .milestone-fullscreen {margin-top: rem-calc(30);
        @include breakpoint(medium) {margin-top: rem-calc(120);}
        @include breakpoint(medium) {margin-top: rem-calc(150);}
    }

}



// Services Landing
// ------

body.page-template-page-template-services-landing {
    .site-footer {border-top: 1px solid $white;}
}

.services-landing {

    &__header {
        .panel {min-height: calc(100vh - #{rem-calc(60)}); color: $white;
            @include breakpoint(medium) {height: calc(90vh - #{rem-calc(120)}); max-height: 50vw; min-height: rem-calc(500);}
        }
    }

    &__article {background-color: $primary-color; color: $white; text-align: center;
        a {color: $white; display: block; 
            .icon {position: relative; width: rem-calc(140); height: rem-calc(140); margin: #{rem-calc(60)} auto #{rem-calc(20)};
                svg {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
                #BG {opacity: 0;}
            }
            &:hover {
                .icon {
                    #BG {opacity: 1;}
                    #FG path {fill: #fff !important;}
                }
            }

            @include breakpoint(medium) {width: rem-calc(220); margin: #{rem-calc(30)} auto 0;}
        }
    }

}


.service-detail {position: relative;

     &__header {
        .panel {min-height: calc(66vh - #{rem-calc(60)}); color: $white;
            @include breakpoint(medium) {height: calc(90vh - #{rem-calc(120)}); max-height: 50vw; min-height: rem-calc(500);}
        }
    }
}

#service-nav {display: none; padding: rem-calc(15 0); color: $white; background: $secondary-color; position: fixed; z-index: 2; left: 0; top: rem-calc(60); width: 100%; transition: top .4s ease-in-out, background .4s .4s ease-in-out;
    
    a {@include header(); display: inline-block; font-size: $small-font-size; color: $white; padding: rem-calc(5 15);
        &.active {color: $primary-color;}
    }

    @include breakpoint(768) {display: block; top: rem-calc(120); background: transparent; text-align: center;
        .minimize + .site-content & {top: rem-calc(60); background-color: $secondary-color;}

        .cell {display: flex; flex-flow: row nowrap; justify-content: space-between; padding: 0;}
    }

    @include breakpoint(large) {
        
    }
}


// Services Detail Components
// ------

.component {


    // Text Area
    // ------

    &__text-area {text-align: center;
        a {font-weight: $global-weight-bold; display: inline-block; position: relative;
            &:after {content: ""; display: block; position: absolute; left: 0; width: 100%; height: rem-calc(3); bottom: rem-calc(-5); background-color: $secondary-color;}
        }
    }



    // Horizontal Icon List
    // ------

    &__horizontal-icon-list {background-color: $lighter-gray;
        &.dark {background-color: $primary-color; color: $white;}

        h5 {
            &:after {content: ""; display: block; width: 85%; height: rem-calc(3); background-color: $secondary-color; margin-top: rem-calc(15);}

            @include breakpoint(medium) {max-width: rem-calc(260);}

            @include breakpoint(small only) {text-align: center;
                &:after {width: 50%; margin-left: auto; margin-right: auto; margin-bottom: rem-calc(40);}
            }
        }

        .list-item {text-align: center;}
        .icon {height: rem-calc(96); margin-bottom: rem-calc(5);
            @include breakpoint(small only) {height: rem-calc(64); margin-bottom: rem-calc(10);}
        }   
        p {max-width: rem-calc(160); margin-right: auto; margin-left: auto;
            @include breakpoint(small only) {font-size: rem-calc(16);}
        }
    }



    // Vertical Icon List
    // ------

    &__vertical-icon-list {

        &.stacked {text-align: center;
            .list-item {position: relative; padding-top: calc(10vw + #{rem-calc(40)});
                &:before {content: ""; display: block; position: absolute; left: calc(50% - #{rem-calc(1.5)}); top: rem-calc(20); height: 10vw; width: rem-calc(3); background-color: $secondary-color;}

                p {max-width: rem-calc(720); margin-right: auto; margin-left: auto;}
            }

            @include breakpoint(medium) {
                .list-item {padding-top: calc(5vw + #{rem-calc(40)});
                    &:before {height: 5vw;}
                }
            }
        }

        &.side-by-side {

            h5 {
                &:after {content: ""; display: block; width: 100%; height: rem-calc(3); background-color: $secondary-color; margin-top: rem-calc(15);}

                @include breakpoint(large) {max-width: rem-calc(180); margin-top: rem-calc(30);
                    &:after {margin-top: rem-calc(20);}
                }

                @include breakpoint(medium down) {text-align: center;
                    &:after {width: 85%; margin-left: auto; margin-right: auto; margin-bottom: rem-calc(40);}
                }
            }

            .list-item {margin-bottom: rem-calc(45); display: flex; flex-flow: row nowrap; 

                .icon {flex: 0 0 #{rem-calc(90)}; margin-right: rem-calc(45); margin-bottom: 0; position: relative;
                    &:before {content: ""; display: block; position: absolute; top: calc(50% - #{rem-calc(1.5)}); right: rem-calc(-45); width: rem-calc(45); height: rem-calc(3); background-color: $secondary-color;}
                }
                .description {flex: 1 1 auto; padding: rem-calc(0 0 0 20);
                    p.lead {margin: .5rem 0;}
                }

                @include breakpoint(medium) {align-items: center;
                    .icon {flex: 0 0 #{rem-calc(120)}; margin-right: rem-calc(60);
                        &:before {right: rem-calc(-60); width: rem-calc(60);}
                    }
                    .description {padding: rem-calc(0 0 0 30);}
                }

                @include breakpoint(large) {align-items: center;
                    .icon {flex: 0 0 #{rem-calc(140)}; margin-right: rem-calc(70);
                        &:before {right: rem-calc(-70); width: rem-calc(70);}
                    }
                }

            }

        }
    }
    &__text-area + .component__vertical-icon-list.side-by-side {padding-top: 0;}



    // Vertical Copy List
    // ------

    &__vertical-copy-list {text-align: center;
        .list-item:not(:first-child) {position: relative; padding-top: calc(10vw + #{rem-calc(40)});
            &:before {content: ""; display: block; position: absolute; left: calc(50% - #{rem-calc(1.5)}); top: rem-calc(20); height: 10vw; width: rem-calc(3); background-color: $secondary-color;}
        }

        @include breakpoint(medium) {
            .list-item:not(:first-child) {padding-top: calc(5vw + #{rem-calc(40)});
                &:before {height: 5vw;}
            }
        }
    }



    // Meet the People
    // ------

    &__meet-the-people {
        &.background-grey {background-color: $lighter-gray;
            .bio .summary {background-color: $white;}
        }

        .intro {align-self: center; 
            .entry-content {max-width: rem-calc(270);}

            @include breakpoint(medium) {order: 2;
                .entry-content {margin-right: auto; margin-left: auto;}
            }
        }
    }



    // Side by Side
    // ------

    &__side-by-side {align-items: center;
        &.grey {background-color: $lighter-gray;}

        .img {min-height: 80vw; align-self: stretch;
            @include breakpoint(medium) {min-height: 40vw;}
        }

        .entry-content {padding-left: rem-calc(20); padding-right: rem-calc(20);
            @include breakpoint(medium) {padding-left: rem-calc(30); padding-right: rem-calc(30); max-width: $global-width / 2; margin-right: 0; margin-left: auto;}
        }

        &.right {
            @include breakpoint(medium) {
                .cell:not(.img) {order: 2;}
                .entry-content {margin-right: auto; margin-left: 0;}
            }
        }
    }



    // Quote
    // ------

    &__quote {

        @include breakpoint(medium) {background-color: $white;
            
            .cell.medium-shrink:first-child {background-color: $primary-color;}

            header.cell.medium-auto {background-color: $primary-color;
                .entry-content {
                    h2 {color: $white;}
                    .connection {background-color: $secondary-color;}
                }
            }
            section.cell.medium-auto {
                .entry-content {
                    .connection {background-color: $primary-color;}
                }
            }
        }

        @include breakpoint(small only) {background-color: $primary-color; color: $white;
            .button {background-color: $secondary-color; color: $white;
                &:hover {background-color: darken($secondary-color, 10%);}
            }
        }
    }



}



// Blog
// ------

.byline {color: $secondary-color;}

.archive-header.page-header {

    @include breakpoint(medium) {min-height: 0;}

    .page-header__main {
        @include breakpoint(small only) {padding-bottom: rem-calc(30);}
    }

    .page-header__side {

        .filter {
            ul {list-style: none; margin: 0; display: flex; flex-flow: row nowrap; justify-content: flex-end; align-items: stretch;
                li {flex: 1 1 auto; margin: 0; text-align: center; display: flex; flex-flow: column nowrap; justify-content: stretch;
                    a {@include header(); flex: 1 1 auto; display: block; padding: rem-calc(4 8); border: #{rem-calc(2)} solid $secondary-color; color: $secondary-color; line-height: 1.2;
                        &.active {background-color: $secondary-color; color: $white;}
                        &:hover {color: $primary-color;}
                    }
                    &:not(:last-child) a {border-right: 0;}
                }
            }
        }

        @include breakpoint(small only) {background-color: $white; padding-top: rem-calc(30);}

    }

}

body.blog, body.archive {

    .ajax-post {padding: rem-calc(20 0 30); border-bottom: #{rem-calc(3)} solid $secondary-color;
        .byline {
            + h3 {margin-top: 0; margin-bottom: .5em;}
        }

        img {display: block; margin-top: 2rem; margin-bottom: 2rem; margin-left: 0; margin-right: auto;}

        @include breakpoint(medium) {padding: rem-calc(45 0 30);}
    }
}

body.single {
    .site-content .panel {background-color: $white;
        img {display: block; margin-top: 2rem; margin-bottom: 2rem; margin-left: 0; margin-right: auto;}
    }
}




// Contact Template
// ------

.contact {
    .panel {background-color: $secondary-color;}
    .heading {font-family: $body-font-family; text-transform: none; font-weight: $global-weight-bold; letter-spacing: 0; margin-bottom: 1rem;}
    .sub-heading {color: $white; 
        @include breakpoint(medium) {
            &:before {content: ""; display: inline-block; width: 1.5rem; margin-right: .75rem; height: rem-calc(3); background-color: $primary-color;}
        }
        @include breakpoint(small only) {font-size: rem-calc(12);}
    }
    .entry-content {margin: rem-calc(45 0 0);
        * {line-height: 1.4; letter-spacing: 0 !important;
            &a:hover {color: $white; }
        }

        @include breakpoint(medium) {margin: rem-calc(75 0);}
    }

    .locations {
        .medium-4, .medium-8 {
            &:before {content: ""; display: block; margin: rem-calc(15 0 30); width: calc(100% - #{rem-calc(90)}); height: rem-calc(3); background-color: $primary-color;}
        }

        h3 {color: $white; margin-top: rem-calc(60);}
        h5 {color: $white; max-width: calc(100% - #{rem-calc(90)});}
        address {font-style: normal;}
        ul {list-style: none; margin: 0;
            li {margin: 0;
                &.phone span:before {content: "Tel: "; display: inline;}
                &.fax span:before {content: "Fax: "; display: inline;}
            }
            a {color: $white;}
        }
        @include breakpoint(medium) {
            .double {display: flex; flex-flow: row nowrap; 
                h5 {max-width: rem-calc(270);}
                .address {flex: 0 0 50%; padding-right: rem-calc(15); font-style: normal;}
                .links {flex: 0 0 50%; padding-left: rem-calc(15);}
            }
        }
    }

}




// Quote Template
// ------

.quote {
    .panel {background-color: $white;}

    .heading {font-family: $body-font-family; text-transform: none; font-weight: $global-weight-bold; letter-spacing: 0; margin-bottom: 1rem;}
    .sub-heading {
        @include breakpoint(medium) {
            &:before {content: ""; display: inline-block; width: 1.5rem; margin-right: .75rem; height: rem-calc(3); background-color: $secondary-color;}
        }
        @include breakpoint(small only) {font-size: rem-calc(12);}
    }
    .gform_wrapper {margin: rem-calc(45 0 0);
        @include breakpoint(medium) {margin: rem-calc(75 0);}
    }

}

.services-select {margin: rem-calc(30 0);

    input[type=checkbox],
    input[type="radio"] {
        + label {text-align: center; line-height: 1.2; width: 100%;

            span {display: block; width: rem-calc(72); height: rem-calc(72); margin: 1rem auto; background: transparent no-repeat center/contain;
                &.cad-customs {background-image: url(../images/service-select-cad.svg);}
                &.usa-customs {background-image: url(../images/service-select-usa.svg);}
                &.freight {background-image: url(../images/service-select-freight.svg);}

                @include breakpoint(medium) {width: rem-calc(116); height: rem-calc(116);}
            }

            &:hover {color: $secondary-color;
                // span.cad-customs {background-image: url(../images/service-select-cad-sel.svg);}
                // span.usa-customs {background-image: url(../images/service-select-usa-sel.svg);}
                // span.freight {background-image: url(../images/service-select-freight-sel.svg);}
            }

            &:before {display: none;}
        }

        &:checked + label span {

            &.cad-customs {background-image: url(../images/service-select-cad-sel.svg);}
            &.usa-customs {background-image: url(../images/service-select-usa-sel.svg);}
            &.freight {background-image: url(../images/service-select-freight-sel.svg);}

        }
    }

    ul {display: flex; flex-flow: row nowrap; justify-content: space-between;
        li {display: block; flex: 0 0 auto; padding-right: 2rem;
            @include breakpoint(small only) {flex: 0 0 33.3333%;}
        }
    }

    @include breakpoint(medium) {margin: rem-calc(45 0);}
}


// Basic Page
// ------

.basic-page .panel {background-color: $white;}